
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "88c16f23-ce56-46b1-a3aa-9ef553e85759"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/vercel/path0/apps/app/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
